<template>
  <auth-card
    ref="authCard"
    :loading="loading"
    @submit="login"
  >

    <template #title>
      Login
    </template>

    <template #form>
      <!-- email -->
      <form-validation-field
        ref="emailField"
        v-model="email"
        name="email"
        rules="required|email"
        label="Email"
        type="email"
        placeholder="john@example.com"
        autofocus
      />

      <!-- password -->
      <form-validation-password-input
        v-model="password"
        rules="required"
      >
        <template #label>
          <div class="d-flex justify-content-between">
            <label for="password">Password</label>
            <b-link :to="{name:'auth-forgot-password'}">
              <small>Forgot Password?</small>
            </b-link>
          </div>
        </template>
      </form-validation-password-input>

      <!-- checkbox -->
      <b-form-group>
        <b-form-checkbox
          id="remember-me"
          v-model="rememberMe"
          name="remember-me"
        >
          Remember Me
        </b-form-checkbox>
      </b-form-group>
    </template>

    <template #button-text>
      Sign in
    </template>

    <template #footer>
      <span>New on our platform? </span>
      <b-link :to="{ name:'auth-register' }">
        <span>Create an account</span>
      </b-link>
    </template>

  </auth-card>
</template>

<script>
import {
  BFormCheckbox,
  BFormGroup,
  BLink,
} from 'bootstrap-vue'
import { email as validateEmail, required } from '@validations'
import AuthMixin from '@/mixins/auth'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'
import FormValidationPasswordInput from '@/components/forms/validation/FormValidationPasswordInput.vue'
import AuthCard from '@/components/auth/AuthCard.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    AuthCard,
    BFormGroup,
    BLink,
    BFormCheckbox,
    FormValidationField,
    FormValidationPasswordInput,
  },
  mixins: [AuthMixin],
  data() {
    return {
      email: '',
      password: '',
      rememberMe: true,
      loading: false,
      errorMessage: 'These credentials do not match our records.',
      // validation rules
      required,
      validateEmail,
    }
  },
  watch: {
    password() {
      if (this.$refs.authCard.$refs.form.errors.email[0] === this.errorMessage) {
        this.$refs.emailField.$refs.validation.reset()
      }
    },
  },
  methods: {
    login() {
      this.loading = true

      useJwt.login(true, { email: this.email, password: this.password })
        .then(() => this.welcomeUser())
        .catch(error => {
          const errors = error.response.status === 404 ? { email: [this.errorMessage] } : error.response.data.errors

          this.$refs.authCard.$refs.form.setErrors(errors)
          this.loading = false
        })
    },
  },
}
</script>
